import Button from "@src/deprecatedDesignSystem/components/Button";
import { useToast } from "@src/hooks/useToast";
import { useTracking } from "@src/hooks/useTracking";
import { launchConfetti } from "@src/utils/confetti";
import { FC, useCallback } from "react";

type Props = {
  style?: React.CSSProperties;
};
const UpgradeButton: FC<Props> = ({ style }) => {
  const { trackEvent } = useTracking();
  const { addToast } = useToast();
  const onClick = useCallback(() => {
    launchConfetti();
    trackEvent("clicked_upgrade_button", {});
    addToast({
      iconType: "star",
      message: "Nice! Someone from our team will reach out to you shortly!",
    });
  }, [trackEvent, addToast]);
  return <Button text="Contact sales" onClick={onClick} style={style} />;
};

export default UpgradeButton;
