import { UserSelectionInput } from "@src/types.generated";
import { useMemo, useState } from "react";

type Props = {
  input?: UserSelectionInput;
  allowUserTypes: boolean;
};
type Return = {
  userSelectionInput: UserSelectionInput;
  setUserSelectionInput: (input: UserSelectionInput) => void;
};

const useUserSelectionInputState = (props: Props): Return => {
  const [userSelectionInput, setUserSelectionInput] = useState<
    UserSelectionInput | undefined
  >(props.input);

  const adjustedInput = useMemo(() => {
    const hasSelectedRoles =
      userSelectionInput?.roleIds && userSelectionInput?.roleIds.length > 0;
    const hasSelectedLocations =
      userSelectionInput?.locationIds &&
      userSelectionInput?.locationIds.length > 0;
    const hasSelectedLocationGroups =
      userSelectionInput?.locationGroupIds &&
      userSelectionInput?.locationGroupIds.length > 0;
    const hasSelectedRoleGroups =
      userSelectionInput?.roleGroupIds &&
      userSelectionInput?.roleGroupIds.length > 0;
    const selectAllRoles =
      !hasSelectedRoles &&
      !hasSelectedRoleGroups &&
      (hasSelectedLocations || hasSelectedLocationGroups);

    if (props.allowUserTypes) {
      return {
        allLocations: false,
        userIds: userSelectionInput?.userIds,
        locationGroupIds: userSelectionInput?.locationGroupIds,
        roleGroupIds: userSelectionInput?.roleGroupIds,
        locationIds: userSelectionInput?.locationIds,
        roleIds: userSelectionInput?.roleIds,
        allManagers: !!userSelectionInput?.allManagers,
        allAdmins: !!userSelectionInput?.allAdmins,
        allTrainees: !!userSelectionInput?.allTrainees,
      };
    }

    return {
      allLocations: false,
      userIds: userSelectionInput?.userIds,
      locationGroupIds: userSelectionInput?.locationGroupIds,
      roleGroupIds: userSelectionInput?.roleGroupIds,
      locationIds: userSelectionInput?.locationIds,
      roleIds: userSelectionInput?.roleIds,
      allManagers: !!selectAllRoles,
      allAdmins: !!selectAllRoles,
      allTrainees: !!selectAllRoles,
    };
  }, [
    props.allowUserTypes,
    userSelectionInput?.allAdmins,
    userSelectionInput?.allManagers,
    userSelectionInput?.allTrainees,
    userSelectionInput?.locationGroupIds,
    userSelectionInput?.locationIds,
    userSelectionInput?.roleGroupIds,
    userSelectionInput?.roleIds,
    userSelectionInput?.userIds,
  ]);

  return useMemo(() => {
    return {
      userSelectionInput: adjustedInput,
      setUserSelectionInput,
    };
  }, [adjustedInput]);
};

export default useUserSelectionInputState;
